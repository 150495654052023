import React from 'react';
import ImgTricouri from 'images/banner.gif'
import "./Ad.scss";


function PintAd(){
    const href = "https://masinideteren.ro/shop/categorie-produs/tricouri/"

    return (
        <div className="cars-list__single-ad pint-ad">
            <a href={href} target="_blank" rel="nofollow" >
                <img src={ImgTricouri} alt="ad-tricouri" />
                <div className="ad-btn">Vezi toate modelele</div>
            </a>
        </div>
    )
}

export default PintAd;